import React from 'react'
import "../styles/pages/landing-page.scss";
import { Link,  } from "react-router-dom";
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
export default function privacyPolicy() {
  return (
    <div className="app-landing-page">
      <div className="app-landing-page-header">
        <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/">
            <img className="app-landing-page-logo" src="/images/landing-page/logo3.png" alt=""/>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="/#services">Services</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="/#about">About</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="/#contactus">Contact Us</a>
                </li>
                <li>
                  <Link to="/login">
                  <Button type="button" className="app-home-cta" variant="contained">Get Started</Button>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="policy-statement">
          <div className="container">
            <h1>Privacy Policy</h1>
            <p>This Privacy Policy is a legally binding document between You and BeatRoot Finserv Private 
              Limited (hereinafter referred to as “Company/We/Our/Us”), which will govern the relationship 
              between You and the Company for Your use of mobile application, software or the website 
              operated by the Company under the name and style “Score10” (hereinafter referred to as 
              “Application”). This Privacy Policy, shall be read along with the Terms and Conditions, which
              help You understand what information We collect, why We collect it, and how You can update,
              manage, export, and delete Your information. The terms of this Privacy Policy will be effective 
              upon Your acceptance of the same (directly or indirectly in electronic form, by clicking on the 
              ‘I Accept’ tab or by use of the Application or by other means).</p>

              <p>This Privacy Policy covers both “online” (e.g., web and mobile services, including any websites 
              and mobile applications operated by Us however accessed and/or used, whether via personal 
              computers, mobile devices or otherwise) and “offline” (e.g., collection of data or registration 
              of documents) activities.</p>

              <p>This document is published as an electronic contract and shall be construed in accordance with 
              the provisions of the Information Technology (Reasonable Security Practices and Procedures 
              and Sensitive Personal Data of Information) Rules, 2011 under Information Technology Act, 2000 
              and is subject to the relevant guidelines, directions, circulars, etc. issued by Reserve Bank of 
              India, National Housing Bank, IRDAI or any other government authority, as amended from time to time.</p>

              <p>Please read this Privacy Policy carefully. By using this Application, You indicate that You 
              understand, agree and consent to the terms of Our Privacy Policy, to Our processing of 
              information for the purposes given in this Privacy Policy as well as the information on the 
              web and from Third Parties. If You do not agree with the terms of this Privacy Policy, please do not 
              use this Application. You hereby provide Your unconditional consent or agreement 
              to the Company as required under Section 43A and Section 72A of Information Technology Act, 
              2000 or rules made thereunder, as amended from time to time.</p>

             <p><b> Note:</b> Please ensure that any new users consent to the privacy policy, terms of use, by way of a 
             “click-wrap agreement” i.e. by clicking on “I Consent” / “I Agree” tab after the same has been read.</p>

             <p>This Privacy Policy shall be deemed to be an integral part of the Terms of Service and words not 
              defined herein shall have the meaning ascribed to it in the Terms of Service.</p>

              <h2>1. SCOPE AND APPLICATION OF POLICY</h2>
              <p>The Platform regulates the processing of information relating to You and grants You various 
              rights with respect to Your personal data. This Privacy Policy applies to anyone who is either:</p>

              <ul>
              <li className='mt-3'>a person who has approached Score10 for access to payroll and other information of the End 
              User basis consent of the End User ("Customer", "You", "Your" or "User"); or</li>

              <li className='mt-3'>a person whose data/information is being accessed/transmitted/uploaded/stored subject to 
              these Terms of Use (“End user”, "You", “Your” or "User"); and
              </li>
              
              <li className='mt-3'> an internet surfer ("You" or "User").</li>

              <p>This Privacy Policy also applies to information we collect from or about Users, Service recipients, 
              Service providers or any other persons who use the Company platform.
              We adhere to the requirements of the data protection laws and regulations (as amended 
              from time to time) established in India. Accordingly, this Privacy Policy is published in accordance with 
              Information Technology Act, 2000 and rules thereunder as amended from time to time.</p>

              </ul>
              <h2>(I) Third Party information/website</h2>
              <p>This Privacy Policy describes our privacy practices for the Platform and Services that are being 
              provided by us. However, this Privacy Policy does not apply to those affiliates, agents and 
              partners that have their own privacy policy. We use third party vendors for hardware, software, 
              networking, storage, and/or ancillary technology required to provide the Services like AWS.
              In such situations, we recommend that you read the privacy policy on the applicable website.</p>
              <p>If You provide Score10 with Personal Information about someone else, You confirm that they are
              aware that You have provided their information and they have consented to Score10’s use of their 
              information according to the Privacy Policy. This Privacy Policy applies to all the current and 
              former visitors, users and others who access our website or use our API for any purpose or use 
              the information obtained using our Services</p>

              <h2>2. CONSENT</h2>
             <p> By using the Platform and by providing Your information to us or access to the information with 
              the Information Provider (as defined below), You consent to the collection, transmission, storage and
              use of such information in accordance with this Privacy Policy. You consent that all 
              information is being provided by You voluntarily. You specifically agree and consent to us 
              collecting, storing, processing and sharing information (including Personal Information) related to 
              You with Customers (as defined below), third parties, service professionals or to registered 
              business partner/users and in accordance with the terms as set out in this Privacy Policy.</p>
             <p>The Personal Information that is collected by us from the End Users is done for the benefit and 
             on behalf of our Customers who are required to take your explicit consent before you share any 
             data with us or before you provide us any access to your personal data. The obligation and 
             responsibility of taking necessary consent from the End User is on the Customer. You 
             acknowledge that Score10 will not be liable for any action or omission of the Customer with 
             respect to the Personal Information and the utilization of such Personal Information by the Customer.</p>
             <p>You shall have the right to withdraw the consent at any time by communication in writing 
             addressed at support@Score10.com.</p>

             <h2>3. COLLECTION OF INFORMATION</h2>
             <p>“Personal Information” includes all information about 
              the User, including all information about persons recorded in structured filing systems (e.g., personnel files, salary information), etc.- 
              essentially the type of information that is identifiable to a particular individual. Some Personal 
              Information may be classified as ‘sensitive’ in some jurisdictions and generally, stricter rules 
              apply. We collect such information from the account, of which You provide access to Us, which 
              further helps Us personalize and continually improve Your experience at the Platform</p>
              <p>We only gather information by accessing the account for which You consented to give access, 
              such as, when You create or modify Your account, contact customer support, provide consent to 
              access Your salary details or otherwise communicate with Us. We collect only such Personal 
              Information which is for lawful purpose connected with Our Services and necessary to be 
              collected by Us for such purpose.</p>
              <p>We may collect personal information of the following nature:</p>
              <ul>
                <li>demographic data including but not limited to age, sex, race / ethnicity;</li>
                <li>personal details such as name, address, contact information, referral name, etc.;</li>
                <li>physical or mental health or condition;</li>
                <li>sexual orientation;</li>
                <li>commission or alleged commission of any offence;</li>
                <li>professional career related information or otherwise;</li>
                <li> KYC details including but not limited to Aadhar number, PAN, Voters Id, License and/ or</li><li>passport details;</li>
                <li>insurance details;</li>
                <li> salary information and receipts;</li>
                <li>nature of Service required to be rendered;</li>
                <li>pages you visit, products you have viewed, compared etc. for doing our internal study on 
                improving our service to our customers.</li>
              </ul>

              <h2>(II) Registration to the Service</h2>   
              <p>Registration to our Service is done through the Platform and/ or any other medium that the 
              Company may specify from time to time. During registration, we shall collect part or whole of the 
              information as mentioned in this Privacy Policy by logging into the account, the access of which 
              is provided by You.</p>  
              <p>When You access the Platform, we may automatically collect information such as the type of 
              internet browser and operating system used, domain name of the website from which You came, 
              number of visits, average time spent on the Platform, pages viewed, etc. We use this information 
              to ensure the Platform function properly, monitor the relevancy of the Platform and improve their 
              performance or content.</p>  

              <h2>4. HOW WE COLLECT</h2>   
              <p>If You provide your third-party account credentials to us, You understand that access to such 
              platform of the Information Provider may be provided to the Customer through the API as well as 
              content and information in those accounts may be transmitted to the account of the Customer 
              which may be hosted by us or otherwise transmitted to the Customer. We may also require You 
              to manually upload Your payslip and similar documents which will then be transmitted to the 
              Customer. We may provide the information one time or continuously on a real time basis, 
              depending on the requirement of the Customer and Your consent. We may also collect 
              information through Your communications with our customer-support team if You contact us for 
              support. We may use and store Your information obtained from You or the Information Provider 
              only for purposes as have been detailed in this Privacy Policy.</p>    

              <h2>5. LIABILITY</h2>
              <p>All such information described above, shall be deemed to be transmitted/given access to or 
              uploaded willingly and without any coercion. No liability of any form pertaining to the 
              authenticity/genuineness/misrepresentation/fraud/negligence, etc. of any information provided 
              by You or as available in the payroll system of the employer, shall be on the Company, nor will the 
              Company be in any way responsible to verify any information obtained from the End User and it 
              shall always be the responsibility of the Customer/End User to verify/review the data. Also, You 
              understand that the use of Your Personal Information by the Customer will depend on your 
              agreement with the Customer and the same is not controlled by Score10. The Company shall also 
              not be liable to End User for any action or omission of the Customer or the Information Provider.</p>

              <p>The Platform may be linked to third-party websites/apps (“Third-Party Sites”) that may collect 
              Your Personal Information. The Company is not in any manner responsible for the security of 
              such Third-Party Sites or their privacy practices or content. Such Third-Party Sites may have 
              their own privacy policies governing their storage and retention of your information that You may 
              be subject to. This Privacy Policy does not govern any information provided to, stored on, or 
              used by such Third-Party Sites or third-party providers and we recommend that You review the 
              applicable privacy policy when You enter a Third-Party Site. You agree and acknowledge that the 
              Company is not liable for the information published by any Third-Party Site.</p>

              <h2>6. SHARING AND DISCLOSURE OF THE INFORMATION</h2>
              <li className='mt-3'>You hereby unconditionally agree and permit that Company may transfer, share, disclose or 
              part with all or any of Your Information, within and outside of the Republic of India to Company 
              and to third party Service providers/ partners/ banks and financial institutions for one or more of 
              the purposes listed above or as may be required by applicable law. In such cases, we will attempt to 
              contractually oblige the receiving third parties of the Information to ensure the same level of 
              data protection that is adhered to as by Company under applicable law.</li>
              <li className='mt-3'>You further agree that such disclosure, sharing and transfer of Your Personal Information 
              and Non-Personal Information shall not cause any wrongful loss to You or to any third party, or any 
              wrongful gain to us or to any third party</li>
              <li className='mt-3'> We disclose the information to the third parties in the method specified below, including, but 
              not limited to the purposes of collection of information as mentioned above</li>
              <li className='mt-3'>We are a Service provider, appointed by the Customer to provide Your Personal Information to 
              the Customer through our Platform and therefore, we disclose Your Personal Information as 
              obtained from You or the Information Provider to the Customer. At times, we may also host such 
              information on our Platform for review by the Customer and provide them such information in 
              such format as may be required by them. We may disclose the Personal Information one time or 
              continuously on a real time basis, depending on the requirement of the Customer and Your 
              consent as given to the Customer.</li>
              <li className='mt-3'>We will be disclosing Your information to employees, agents, officers, third party partners, legal 
              advisors or auditors but only on a need to basis and in accordance with the provisions of 
              this Privacy Policy.</li>

              <h2 className='mt-2'>7. STORAGE AND OTHER UTILISATION OF THE INFORMATION</h2>
              <p>Our Services are aimed at, inter alia, providing the Customers with connectivity to the payroll 
              system, financial information of the End User through a single API. Our Services, inter alia, enable 
              End Users to connect to payroll systems, financial accounts and related platforms offered by 
              third parties, including but not limited to payroll providers and employers (each an “Information 
              Provider”) and provide user data to our Customers. We may use and store the information 
              provided by you or obtained through the Information Provider or any non-Personal Information 
              for the following purpose:</p>
              <p>(a)  share the same with third party Service providers to enable them to effectively sale their 
              products/ provide their Services to You;</p>
              <p>(b)  provide, maintain, and improve upon our business. For example, to introduce and facilitate 
              payments, develop new features, provide customer support to Users, develop features, 
              authenticate users, and send Service updates and administrative messages;</p>
              <p>(c)  perform internal operations, including, for example, to prevent fraud and abuse of 
              the Platform; to troubleshoot software bugs and operational problems; to conduct data analysis, 
              testing, and research; and to monitor and analyse usage and activity trends;</p>
              <p>(d)  send or facilitate communications;</p>
              <p>(e)  send You communications we think will be of interest to You, including information about 
              products, services, promotions, news, and events of Company and other companies, where 
              permissible and according to local applicable laws; and to process contest, and fulfil any related 
              awards;</p>
              <p>(f)  personalize and improve the Services, including to provide or recommend features, content, 
              social connections, referrals, and advertisements;</p>
              <p>(g)  to detect, prevent and protect the Company from any errors, frauds, and other criminal or 
              prohibited activity;</p>
              <p>(h)  to create aggregate or statistical information that does not directly identify a specific person, 
              and We may share that information with Third Parties.</p>
              <p>(i)  for any other purpose, for which You have granted consent;</p>
              <p>Where we have no continuing legitimate business or need to store Your Personal Information, we 
              will either delete or anonymize it or, if this is not possible (for example, because your personal 
              data has been stored in backup archives), then we will securely store Your personal data and 
              isolate it from any further processing until deletion is made possible. However, we may continue 
              to retain the non-Personal Information.</p>

              <h2>8. DELETION OF INFORMATION</h2>
              <p>If required by You, Score10 will delete the Personal Information provided by You, subject to 
              applicable laws, as amended from time to time. You can always refuse to supply your Personal 
              Information to us. However, this may restrict you from using the Services that are being provided 
              by the Customer. Further, we are not liable for the deletion/non deletion of the Personal 
              Information provided to the Customer by us based on your consent.</p>

              <h2>9. CONFIDENTIALITY</h2>
              <p>The Personal Information and other usage information we collect is securely stored within our 
              databases, and we use standard, industry-wide, reasonable security practices such as 
              encryption, firewalls and SSL (Secure Socket Layers) for protecting Your information. However, 
              as effective as encryption technology is, no security system is impenetrable. We cannot 
              guarantee the security of our databases, nor can we guarantee that information You supply 
              won't be intercepted while being transmitted to us over the internet or wireless communication, 
              and any information You transmit to the Company You do at Your own risk. We recommend that 
              You not disclose Your password to anyone.</p>

              <h2>10. CHANGES TO THIS PRIVACY POLICY</h2>
              <ul>
                <li className='mt-3'>Our business changes constantly and our Privacy Policy and Terms of Use will also change 
                accordingly. We may e-mail periodic reminders of our notices and conditions, unless You have 
                instructed us not to, but You should check our Platform frequently to see recent changes</li>
                <li className='mt-3'>We, at our sole discretion, reserve the right to update, change or modify this Privacy Policy at 
                any time to reflect any (including but not limited to) changes in the law, the data collection, and 
                practices, the features of the Platform or advances in technology. The amendment to this Privacy 
                Policy shall come into effect from the time of such update, change or modification and the same 
                will be published here.</li>
                <li className='mt-3'>You are requested to review the Privacy Policy carefully from time to time. Use of information 
                  collected is subject to the Privacy Policy in effect at the time such information is used. The 
                  changes to this Privacy Policy shall be treated as read, recognized, understood, consented and 
                  accepted if You continue to use the Platform post such changes.</li>
                <li className='mt-3'>Unless stated otherwise, our current Privacy Policy applies to all information that we have 
                  about You and Your account. We stand behind the promises we make, however, and will never 
                  materially change our policies and practices to make them less protective of User information 
                  collected in the past without the consent of affected User.</li>
              </ul>

              <h2>11. DISPUTE RESOLUTION AND GOVERNING LAW</h2>
              <ul>
                <li className='mt-3'>The Terms of Use shall be governed in all respects by the laws of India and any legal 
                proceeding arising out of these Terms of Use will occur exclusively in the courts located in 
                Hyderabad, India.</li>
                <li className='mt-3'>If a dispute arises between You and the Company, our goal is to provide You with neutral and 
                cost-effective means of resolving the dispute quickly. Accordingly, You and the Company hereby 
                agree that we will resolve any claim or controversy at law and equity that arises out of or in 
                relation to the Terms of Use, the Privacy Policy or the Platform will be resolved in accordance 
                with the provisions contained herein. Prior to resorting to formal ways of dispute resolution, we 
                strongly encourage You to first contact Score10 directly to seek a resolution as per the grievance 
                redressal procedure set out here in below.</li>
                <li className='mt-3'>If not resolved with Score10, any further dispute shall be settled amicably by the Parties 
                within  30 (thirty) calendar days of the receipt of the notice of the existence of a dispute. In the event 
                any dispute cannot be resolved within 30 (thirty) calendar days from notice of the dispute, either 
                party may refer the dispute to be finally settled by arbitration in accordance with the Indian 
                Arbitration and Conciliation Act, 1996 and the rules framed thereunder, as amended from time to 
                time. The arbitration proceedings shall be conducted by a sole arbitrator mutually appointed by 
                the parties. The venue and seat of arbitration shall be Mumbai, India and the arbitration 
                proceedings shall be conducted in English language.</li>
                <li className='mt-3'> Unless stated otherwise, our current Privacy Policy applies to all information that we have 
                about You and Your account. We stand behind the promises we make, however, and will never 
                materially change our policies and practices to make them less protective of User information 
                collected in the past without the consent of affected User.</li>
                </ul>

                <h2>12. COOKIES AND OTHER TRACKING TECHNOLOGIES</h2>
                <ul>
                  <li>We use data collection devices such as “cookies” on certain pages of our Platform.</li>

                  <li className='mt-3'> A cookie is a piece of data stored on the User’s computer tied to information about the User. 
                  We may use both session ID cookies and persistent cookies. For session ID cookies, once You 
                  close Your browser or log out, the cookie terminates and is erased. A persistent cookie is a small 
                  text file stored on Your computer’s hard drive for an extended period of time. Session ID cookies 
                  may be used by the Company to track User preferences while the User is visiting the Platform. 
                  They also help to minimize load times and save on server processing. Persistent cookies may be 
                  used to store whether, for example, You want Your password remembered or not, and other 
                  information. Cookies used on the Platform do not contain personally identifiable information.</li>

                  <li className='mt-3'>You may set most browsers to notify You if You receive a cookie, or You may choose to block 
                  cookies with Your browser, but please note that if You choose to erase or block Your cookies, 
                  You will need to re-enter Your original User ID and password to gain access to all/ certain parts 
                  of the Platform.</li>

                  <li className='mt-3'>Tracking technologies may record information such as internet domain and Users names; 
                  Internet protocol (IP) addresses; browser software and operating system types; stream patterns; 
                  and dates and times that our site is accessed. Our use of cookies and other tracking 
                  technologies allows Us to improve Our Platform and Your experience. We may also analyze 
                  information that does not contain Personal Information for trends and statistics.</li>

                  <li className='mt-3'>The cookies allow You to take advantage of some of the Platform’s essential features, we 
                    recommend that You leave them turned on. For instance, if You block or otherwise reject our 
                    cookies, You may not be able to use any Service that requires you to sign in.</li>
                </ul>

                <h2>13. DISCLAIMER</h2>
                <p>We cannot ensure that all of Your private communications and other Personal Information 
                (including sensitive information like credit card information and bank account number) will never 
                be disclosed in ways not otherwise described in this Privacy Policy. Therefore, although we are 
                committed to protecting Your privacy, we do not promise, and You should not expect, that Your 
                Personal Information or private communications will always remain private. As a User of the 
                Platform, You understand and agree that You assume all responsibility and risk for Your use of 
                the Platform, the internet generally, and the documents You post or access and for Your conduct 
                on and off the Platform.</p>

                <h2>14. SECURITY OF PERSONAL INFORMATION</h2>
                <ul>
                  <li className='mt-3'> The Company understands that the confidentiality, integrity, and availability of Your information 
                  are vital to our business operations and our own success. We employ appropriate technical and 
                  organizational security measures at all times to protect the information we collect from You. We 
                  use multiple electronic, procedural, and physical security measures to protect against 
                  unauthorized or unlawful use or alteration of information, and against any accidental loss, 
                  destruction, or damage to information.</li>
                  <li className='mt-3'>The Company is an ISO certified organization and protects Your information as per the 
                  International Standard IS/ISO/IEC 27001 on “Information Technology Security Techniques 
                  Information Security Management System-Requirements”. However, no method of transmission 
                  over the internet, or method of electronic storage, is 100% secure. Therefore, we cannot 
                  guarantee its absolute security. Further, You are responsible for maintaining the confidentiality 
                  and security of Your login id and password, and may not provide these credentials to any Third 
                  Party.</li>
                  <li className='mt-3'> We follow generally accepted industry standards to protect the Personal Information submitted 
                  to us, both during transmission and once we receive it. We host the API in a secure server 
                  environment that uses advanced technology to prevent interference or access from outside 
                  intruders.</li>
                  <li className='mt-3'>Our security practices and procedures limit access to personal information on a need-only 
                  basis</li>
                  <li className='mt-3'>Score10 will strive to protect information and privacy, however, it takes no guarantee of absolute 
                  security when information is transmitted to the Customer. Score10 will not be responsible under 
                  any circumstance for any loss or theft of information due to unauthorised access to Your device 
                  through which You visit website or use the API or any other reasons not attributable directly to 
                  Score10. Score10 will not be responsible for any breach of security due to any actions or events of 
                  any third parties including Customer and Information Provider which are outside its reasonable 
                  control including but not limited to computer hacking, government acts, computer crashes etc.</li>
                  </ul>

                  <h2>15. CONTROL OF YOUR INFORMATION</h2>
                  <ul>
                    <li className='mt-3'>You have the ability to control how Your non-Personal Information is collected and used online. 
                    You also have the ability to choose what Personal Information, including what sensitive personal 
                    information (i.e., your financial information) You provide to us. However, if You choose not to 
                    provide all of the information and data that is requested of You, we may not be able to provide 
                    You with the Services that You have subscribed to.</li>
                    <li className='mt-3'>We believe You should be able to choose what kinds of information You receive via email/SMS. 
                    If You do not want to receive marketing materials by email/SMS, just indicate Your preference 
                    on the contact information for Your account or the ‘opt-out’ or unsubscribe link provided in our 
                    marketing emails and You can also write to us at support@score10.in Please keep in mind 
                    that we will continue to notify You by email /SMS/via phone calls regarding Your services with us,
                    even after You have opted out.</li>
                    <li>You can review the information that we have verified and validated by logging into Your 
                    account at the HR portal of a company.</li>
                  </ul> 

                  <h2>16. SEVERABILITY</h2>
                  <p>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and 
                  severable from all and any other paragraphs herein except where otherwise expressly indicated 
                  or indicated by the context of the agreement.</p>
                  <p>The decision or declaration that one or more of the paragraphs are null and void shall have no 
                  effect on the remaining paragraphs of this Privacy Policy.</p>

                  <h2>17. CONTACT INFORMATION</h2>
                  <p>In case you either have any feedback or comments about this Privacy Policy or any discrepancy 
                  with respect to the processing of any of the information/data You provided to the Company, 
                  You can be sent to the grievance officer mentioned below. We will employ all reasonable efforts to 
                  address the same.</p>
                  <ul>
                    <li className='mt-2'> Grievance Officer</li>
                    <p>In accordance with Information Technology Act, 2000 and rules made there under, as amended 
                    from time to time, the name and contact details of the grievance officer are as follows:</p>
                    <li className='mt-2'>Name of Grievance Officer:  Meet Narayana Swaroop</li>
                    <li className='mt-2'>Email: support@Score10.in</li>
                    <li className='mt-2'>Registered address: Plot No 1/C, Sy No 83/1, Raidurgam, Knowledge City Rd, panmaktha, Hyderabad, 
                    Serilingampalle (M), Telangana 500081</li>
                    <li className='mt-2'>Process of Grievance Redressal</li>
                  </ul>
                  <p className='mt-2'>Any person having a grievance in relation to this Privacy Policy may furnish the grievance, at any 
                  time, to the above-mentioned personnel.</p>
                  <p className='mt-2'>An acknowledgement of the grievance will be issued and generated by the Company for the 
                  benefit of the complainant within 24 hours of it being furnished for information and record.</p>
                  <p className='mt-2'>We may reach out to you for further information and details on receipt of the 
                  complaint/queries/concerns by the Company. We will make all endeavours to resolve the 
                  complaint as soon as possible.</p>
                  <p className='mt-2'>The manner of grievance redressal shall have the following arrangement: -</p>
                  <ul>
                    <li className='mt-2'>The Company shall address the grievance and inform the complainant of its decision within 15 
                    days of the registration of the grievance;</li>
                    <li className='mt-2'>if the decision of the publisher is not communicated to the complainant within the stipulated 15 
                    days, the grievance shall be escalated to the Meet Semlani, which shall be conducted as per 
                    Meet Semlani of the Company.</li>
                  </ul>
          </div>
      </div>
        <div className="app-landing-footer">
            <div className=''>
              <div className="app-landing-footer-content">
              <div className="app-landing-footer-highlight">
              <img src="/images/landing-page/logo3.png" alt=''/>
              <div className="ml-3">
                <p>Score10 <span>TM</span> is a product of Beatroot Finserv Pvt Ltd.</p>
                <h6>CIN: 	U72500TG2022PTC161895</h6>
              </div>
              </div>
              <ul>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                <a href="#about">About</a>
                </li>
                <li>
                  <a href="#contactus">
                  Contact Us
                  </a>
                </li>
                <li>
                  <Link to="/terms-and-conditions">
                  Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/refund-policy">
                  Refund Policy
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy">
                  Privacy Policy
                  </Link>
                </li>
              </ul>
              </div>
              <div className="copy-right-content">

              </div>
            </div>
        </div>
    </div>
  )
}
