import React from 'react'
import "../styles/pages/landing-page.scss";
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
export default function LandingPage() {
  return (
    <div className="app-landing-page">
      <div className="app-landing-page-header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/">
              <img className="app-landing-page-logo" src="/images/landing-page/logo3.png" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <a className="nav-link" href="#services">Services</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="#about">About</a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link" href="#contactus">Contact Us</a>
                </li>
                {/* <li>
                  <Button type="button" className="app-home-cta" variant="contained">Get Started</Button>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="app-landing-page-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="app-landing-page-banner-left">
                <div className="app-landing-page-banner-left-content">
                  <h3>Verify your Tenants ID and Address for <b>FREE NOW</b> on Whatsapp</h3>
                  <h1></h1>
                  <p>Score 10 offers tenant screening solutions to landlords and Property managers so that they can identify top quality applicants. </p>
                  <div className="app-landing-page-banner-left-content-cta">
                    <p>Verify your tenant now - Rent with Confidence </p>
                    <div style={{ display: 'flex' ,flexDirection:'row',flexWrap:'wrap'}}>
                      <img style={{ width: 300, cursor: 'pointer', marginRight: 10, marginTop: 10, height: 150 }} onClick={() => {
                        window.location.href = `https://api.whatsapp.com/send?phone=919505895078&text=Hi%20Score10`;
                      }} src="/images/whatsapp-button.png" />
                      <img style={{ height: 170, cursor: 'pointer', paddingleft: 730 }} onClick={() => {

                        window.open('/images/landing-page/ISO 27001 BEATROOT FINSERV PRIVATE LIMITED.pdf', '_blank');
                        }}
                        src="/images/landing-page/iso 27001.png" />
                      {/* <img src="/images/isocert.jpeg" width={"230"} /> */}
                    </div>

                    {/* <Button  type="button" className="app-home-cta" variant="contained">Get Started</Button> */}

                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="app-landing-page-banner-right">
                <img src="/images/landing-page/banner-imsge.png" />
              </div>
            </div>
          </div>
        </div>
        <img className="scratch-graphic" src="/images/landing-page/scratch-graphic.svg" />
      </div>
      <div className="screen-your-tenants">
        <div id="about"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="screen-your-tenants-left">
                <img src="/images/landing-page/screen-your-tenants.png" />
              </div>
            </div>
            <div className="col-md-5">
              <div className="screen-your-tenants-right">
                <h3>Thoroughly Screen Your Tenants</h3>
                <p>Score 10 offers tenant screening solutions to landlords and Property managers so that they can identify top quality applicants. </p>
                <ul>
                  <li>Rent Out to verified tenants</li>
                  <li>Proper Financial Management</li>
                  <li>Stream Line property Insurance Process </li>
                  <li>Rent Collection</li>
                  <li>Curated Market Place with best in class  Landlord & Renter Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="screen-your-tenants-graphics-content">
          <img className="blink-graphic" src="/images/landing-page/blink-graphic.svg" />
          <img className="home-graphic" src="/images/landing-page/home-graphic.svg" />
        </div>
      </div>
      <div className="graphic-border"></div>

      <div className="score-bigger-credit">
        <div id="services"></div>
        <div className="container">
          <h2 className="score-bigger-credit-heading"> <span>Score10<sup>TM</sup></span>  is More Than a Credit Score</h2>
          <div className="landing-page-mobile-wrapper">
            <div className="landing-page-mobile-app">
              <img src="/images/landing-page/score-10-app.png" />
              <div className="landing-page-mobile-app-features">
                <div className="landing-page-mobile-app-features-item landing-page-mobile-app-features-item1">
                  <h5>Identity Check</h5>
                  <p>We check Pan Card, Voter Id, Aadhaar Card, Passport, Driving license to authenticate and validate your identity documentation.</p>
                  <span></span>
                </div>
                <div className="landing-page-mobile-app-features-item landing-page-mobile-app-features-item2">
                  <h5>Address Check</h5>
                  <p>We verify Tenants permanent address from the AADHAAR/DL/PASSPORT/VOTER ID</p>
                  <span></span>
                </div>
                <div className="landing-page-mobile-app-features-item landing-page-mobile-app-features-item3">
                  <h5>Credit Worthiness</h5>
                  <p>We verify credit worthiness of tenants by checking HIS/HER credit score from credit bureau's</p>
                  <span></span>
                </div>
                <div className="landing-page-mobile-app-features-item landing-page-mobile-app-features-item4">
                  <h5>Court verification</h5>
                  <p>We verify public data base of indian court & criminal records</p>
                  <span></span>
                </div>
                <div className="landing-page-mobile-app-features-item landing-page-mobile-app-features-item5">
                  <h5>Old Landlord References </h5>
                  <p>We verify Tenant's previous timely rental payments and how he/she managed the property</p>
                  <span></span>
                </div>
                <div className="landing-page-mobile-app-features-item landing-page-mobile-app-features-item6">
                  <h5>Employment verification</h5>
                  <p>We verify employment details shared by Tenant along with income details</p>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="score-bigger-credit-para">
            <p>Our proprietary Score helps to identify tenants who are more likely to pay rent on time, treat the property with care, and stay for longer periods of time. Because it is based on analysis of key rental data, it is more reliable than a standard credit score for evaluating your applicants</p>
          </div>
        </div>
      </div>
      <div className="adding-ur-properties">
        <img className="scratch-graphic-adding-properties" src="/images/landing-page/scratch-graphic.svg" />
        <div className="container">
          <div className="d-flex justify-content-center">
            <h2 className="adding-ur-properties-heading">Adding your Property Made easy</h2>
          </div>
          <div className="adding-ur-properties-card">
            <div className="row">
              <div className="col-md-8">
                <div className="adding-ur-properties-card-content">
                  <h3>Free Online <br />Rental Application</h3>
                  <h4>Go Paperless</h4>
                  <div className="adding-ur-properties-card-savetime">
                    <h4>Save Time</h4>
                    <p>Simplify the rental process for applicants and landlords.</p>
                  </div>
                  <div className="adding-ur-properties-card-savetime">
                    <h4>Add an Optional Screening</h4>
                    <p>You can request a tenant screening alongside or after an application is submitted.</p>
                  </div>
                  <img style={{ width: 230, cursor: 'pointer', marginTop: 30 }} onClick={() => {
                    window.location.href = `https://api.whatsapp.com/send?phone=919505895078&text=Hi%20Score10`;
                  }} src="/images/whatsapp-button.png" />
                  {/* <Link to="/login">
                    <Button type="button" className="app-home-cta" variant="contained">Get Started</Button>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="adding-ur-properties-preview">
              <img src="/images/landing-page/app-preview.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="landing-page-contact">
        <div id="contactus"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h4 className="contact-heading">Contact Us</h4>
            </div>
            <div className="col-md-6">
              <div className="landing-page-contact-card">
                <div className="landing-page-contact-card-item">
                  <h4>Reach Us</h4>
                  <p>Monday - Friday <b>9:00 am to 6:00 pm</b> IST</p>
                  <a href="tel:+919505895078">966-669-1678</a>
                  <a className="mt-1" href="tel:+919666692678">966-669-2678</a>

                </div>
                <a href="mailto:support@score10.in" className="landing-page-contact-card-email">
                  <img src="/images/landing-page/mail-icon.svg" />
                  <span>support@score10.in</span>
                </a>
                <div className="contact-address">
                  <p>T-Hub, 20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana 500081</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="app-landing-footer">
        <div className=''>
          <div className="app-landing-footer-content">
            <div className="app-landing-footer-highlight">
              <img src="/images/landing-page/logo3.png" />
              <div className="ml-3">
                <p>Score10 <span>TM</span> is a product of Beatroot Finserv Pvt Ltd.</p>
                <h6>CIN: 	U72500TG2022PTC161895</h6>
              </div>
            </div>
            <ul>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#contactus">
                  Contact Us
                </a>
              </li>

              <li>
                <Link to="/terms-and-conditions">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/refund-policy">
                  Refund Policy
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="copy-right-content">

          </div>
        </div>
      </div>
    </div>
  )
}
